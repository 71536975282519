<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';

	export let additionalClass: string = '';
	export let additionalIconClass: string = '';
	export let width: number | undefined = undefined;
	export let height: number | undefined = undefined;

	let ratio: number | undefined = undefined;

	$: if (width && height) {
		try {
			ratio = width / height;
		} catch {
			// ratio = 1;
		}
	}
	export { additionalClass as class };

	$$restProps;
</script>

{#if ratio}
	<div class={classes(additionalClass, 'relative w-full h-full bg-dark-background aspect-square')}>
		<div class="w-full pt-[{ratio * 100}%]" />

		<div class="absolute inset-0 flex items-center justify-center h-full aspect-square">
			<div class="i-sprenger-image" />
		</div>
	</div>
{:else}
	<div
		class={classes(
			additionalClass,
			'h-full w-full max-w-[320px] aspect-square grid place-items-center inset-0 bg-dark-background'
		)}
	>
		<div
			class={classes(additionalIconClass, 'i-sprenger-image w-6 h-6 text-dark-quaternary m-4')}
		/>
	</div>
{/if}
